import axios from 'axios'
import secrets from '../config/secrets'

const { SPACE_ID, CONTENTFUL_ACCESS_TOKEN } = secrets

const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development'
const contentfulEnv = process.env.GATSBY_CONTENTFUL_ENV || (activeEnv === 'development' ? 'development' : 'master')

const fetch = axios.create({
  baseURL: `https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}/environments/${contentfulEnv}`,
  method: 'POST',
  headers: {
    Authorization: `Bearer ${CONTENTFUL_ACCESS_TOKEN}`,
  },
})

export default fetch
