import { AxiosResponse } from 'axios'
import { useState, useEffect } from 'react'
import fetch from '../graphql/client'

export type Options = {
  inmediate?: boolean
  variables?: Record<string, string | number>
  deps?: unknown[]
}

const useQuery = <T = any>(query: string, options?: Options) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<T>()
  const [error, setError] = useState<Error | string>()

  const executeQuery = async () => {
    try {
      const {
        data: { data: response },
      }: AxiosResponse<{ data: T }> = await fetch({
        data: { query, variables: options?.variables },
      })

      setData(response)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setError(e?.response ?? e)
    }
  }

  useEffect(() => {
    if (options?.inmediate !== false) executeQuery()
  }, options?.deps ?? [])

  return { loading, data, error, executeQuery }
}

export default useQuery
